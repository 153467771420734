import { baseUrl } from "../data/config"
import { request } from "../util/api"

export default class AuthAPI {
  static confirmPhone = (phone: string) =>
    request(baseUrl + "app/auth/confirm-phone", "POST", { phone })

  static confirmOTP = (payload: { phone: string; hash: string; otp: string }) =>
    request(baseUrl + "app/auth/verify-otp", "POST", payload)
}
