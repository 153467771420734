import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import { GlobalDispatchContext } from "../context/GlobalContext.provider"

import Navbar from "../components/Navbar.component"
import AppLayout from "../layouts/AppLayout"
import Footer from "../components/Footer.component"
import AboutUs from "../components/AboutUs.component"

import { isInvalidPhoneNumber } from "../util"
import { ACTIONS } from "../context/actions"
import AuthAPI from "../api/Auth.api"
import { UserCredential } from "firebase/auth"
import { loginClient } from "../util/auth.lib"

let otpHash = ""

const Login = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const [stage, setStage] = useState<"user-info" | "validate">("user-info")
  const [userName, setUserName] = useState("")
  const [phoneNumber, setphoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (stage === "user-info") {
      if (!userName?.length) {
        return e.currentTarget.username.classList.add("is-invalid")
      }

      if (isInvalidPhoneNumber(phoneNumber)) {
        return e.currentTarget.phone.classList.add("is-invalid")
      }

      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return e.currentTarget.email.classList.add("is-invalid")
      }

      return sendOtp()
    } else {
      const otp = e.currentTarget.otp.value
      if (otp.length < 6 || isNaN(otp)) {
        return e.currentTarget.otp.classList.add("is-invalid")
      }
      onVerifyOtp(otp)
    }
  }

  const getformatedPhone = () => {
    return phoneNumber.length === 9
      ? `+94${phoneNumber}`
      : phoneNumber.replace("0", "+94")
  }

  const sendOtp = async () => {
    const phone = getformatedPhone()

    dispatch({ type: ACTIONS.SET_LOADING, payload: true })
    try {
      const data = await AuthAPI.confirmPhone(phone)
      otpHash = data.hash
      setStage("validate")
    } catch (error) {
    } finally {
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    }
  }

  const onVerifyOtp = async (otp: string) => {
    const phone = getformatedPhone()
    dispatch({ type: ACTIONS.SET_LOADING, payload: true })
    try {
      const data = await AuthAPI.confirmOTP({
        phone,
        hash: otpHash,
        otp,
      })
      const authData: UserCredential = await AuthAPI.signInWithCustomToken(
        data.token
      )
      dispatch({ type: ACTIONS.SET_AUTH, payload: authData.user.uid })

      try {
        const client = await loginClient(
          authData.user.uid,
          phone,
          userName,
          email
        )

        if (client) {
          dispatch({ type: ACTIONS.SET_CLIENT, payload: client })
        }
      } catch (error) {}
      navigate("/marketplace/checkout")
    } catch (error) {
      // const otpDoc = document.getElementById("otp")
      // otpDoc?.classList.add("is-invalid")
    } finally {
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    }
  }

  const onClickResend = () => sendOtp()
  const onClickChangeNumber = () => setStage("user-info")

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "username") {
      setUserName(e.target.value)
    } else if (e.target.name === "email") {
      setEmail(e.target.value)
    } else if (e.target.name === "phone") {
      setphoneNumber(e.target.value)
    }
    e.currentTarget.classList.remove("is-invalid")
  }

  const renderBody = () => {
    if (stage === "user-info") {
      return (
        <>
          <span>Let's login first!</span>
          <form onSubmit={onSubmit}>
            <div className="form-group mb-3">
              <label>Your name</label>
              <input
                type="text"
                className="form-control"
                name="username"
                onChange={onChange}
                value={userName}
                key="username"
              />
              <div className="invalid-feedback">Your name is required!</div>
            </div>
            <div className="form-group  mb-3">
              <label>Phone number</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">+94</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  onChange={onChange}
                  maxLength={10}
                  value={phoneNumber}
                  key="phone"
                />
                <div className="invalid-feedback">
                  Please enter a valid phone number.
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control "
                aria-describedby="emailHelp"
                name="email"
                onChange={onChange}
                value={email}
                key="email"
              />
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
              <small id="emailHelp" className="form-text text-muted">
                We'll send the gift vouchers to this email address.
              </small>
            </div>
            <button type="submit" className="theme-btn secondary medium">
              Submit
            </button>
            <br />
            <small className="consent">
              By proceeding, you accept our
              <a href="/terms-of-use">terms and conditions.</a>
            </small>
          </form>
        </>
      )
    } else {
      return (
        <>
          <span>Verify yourself!</span>
          <form onSubmit={onSubmit}>
            <div className="form-group mb-3">
              <label>Enter OTP</label>
              <input
                type="text"
                className="form-control form-control-lg"
                name="otp"
                maxLength={6}
                key="otp"
                onChange={onChange}
                id="otp"
              />
              <div className="invalid-feedback">Invalid OTP!</div>
              <small id="emailHelp" className="form-text text-muted">
                An OTP is sent to {phoneNumber}
              </small>
            </div>

            <p>
              Did not receive the OTP yet?
              <strong onClick={onClickResend}>Resend OTP</strong>
            </p>

            <button type="submit" className="theme-btn secondary medium">
              Submit
            </button>
            <p>
              Want to change the number?
              <strong onClick={onClickChangeNumber}>Change phone number</strong>
            </p>
            <br />
            <small className="consent">
              By proceeding, you accept our
              <a href="/terms-of-use">terms and conditions.</a>
            </small>
          </form>
        </>
      )
    }
  }

  return (
    <AppLayout>
      <div className="container-lg mb-5">
        <div className="login-form">
          <h3>Welcome to GLOWAPP</h3>
          {renderBody()}
        </div>
      </div>
      <AboutUs />
      <Footer />
    </AppLayout>
  )
}

export default Login
