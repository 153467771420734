import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar.component"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContext.provider"
import { ACTIONS } from "../context/actions"

type OwnProps = {
  children: JSX.Element | JSX.Element[]
}

const AppLayout = ({ children }: OwnProps) => {
  const dispatch = useContext(GlobalDispatchContext)
  const { cart, client } = useContext(GlobalStateContext)

  const onClickSignOut = () => {
    dispatch({ type: ACTIONS.SIGN_OUT })
  }

  return (
    <>
      <Helmet>
        <meta http-equiv="content-type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-language" content="en" />
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://www.glowapp.info/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#b3a1cb" />
        <meta property="al:ios:app_name" content="GLOWAPP LK" />
        <meta property="og:title" content="GLOWAPP - where you glow up" />

        <link rel="apple-touch-icon" href="/logo192.png" />

        <link rel="manifest" href="/manifest.json" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Alata&family=Roboto:wght@100;300;400;500&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#b3a1cb" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <title>GLOWAPP</title>
      </Helmet>
      <div className="body">
        <Navbar
          bg="light-gradient"
          cartLength={cart?.length}
          client={client}
          onClickSignOut={onClickSignOut}
        />
        {children}
      </div>
    </>
  )
}

export default AppLayout
